<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header :name="user.fullname"  />
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
            <SideMenu :indexsec="4"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
             
              
             
            <form
              @submit.prevent="
                submit(
                    num_form,
                    color_form,
                    size_form,
                )
              "
            >
                <div class="div_EProduct">
              <p>AGREGAR STOCK</p>
              </div>

              <div class="EPF2_C1">
                  <p class="p-prod ">Producto</p>
              <h2 class="h-prod">{{product.name}}</h2>
              <hr>
               </div>
             
               <div class="row mx-0 row-two ">
                <div class="EPF2_C1">
                  <label class="labelE color-1" for="inputE">Stock</label>
                  <b-form-input   v-model="num_form" required id="inputE" type="text"  pattern="\d*" />
                </div>
               
                <div class="">
                    <label class="labelR color-1" for="inputR">Color</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{color_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.colores" :key="key">
                          <b-dropdown-item @click="changeColor(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="">
                    <label class="labelPo color-1" for="inputPo">Talla</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{size_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.tallas" :key="key">
                          <b-dropdown-item @click="changeSize(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                
                
              </div>
              


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form mtrow">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El stock se agrego correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >AÑADIR OTRO</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import { mapActions } from "vuex";
export default {
  name: "AddStock",
    components: {
    SideMenu,
    Header
  },
  data() {
    return {
     
      num_form:"",
      size_form:"",
      color_form:"",
      size_name:"Talla",
      color_name:"Color",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
    };
  },
  async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     this.getColors('Colores')
     this.getSizes('Tallas')

      let id = this.$route.params.id_product;
      let payload = {
        id: id,
        option: "product",
      };
     this.getInfoById(payload);
     
  },
  computed:{
       colores() {
          return this.$store.getters["color/data"];
        },
        tallas() { 
          return this.$store.getters["size/data"];
        },
         user() {            
            return this.$store.getters['admin/getIdentity'];
        },
         product() {
      return this.$store.getters["main/data"];
      },

  },
  methods: {
     ...mapActions('color', ['getColors']),
     ...mapActions('size', ['getSizes']),
     ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (stock, id_color, id_size) {
           let id_product = this.$route.params.id_product
           id_product = String(id_product)
            id_color = String(id_color)
            id_size = String(id_size)
           let data = { "id_color": id_color,
                        "id_size": id_size,
                        "id_product": id_product,
                        "stock": stock,
                        }

                        console.log(data)
            
            
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'stock', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
       

     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       let id_product = this.$route.params.id_product
       this.$router.push("/editar/producto/"+id_product).catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
  
    changeSize: function (value, name) {
     
       this.size_form = value;
       this.size_name = name;

    },
    changeColor: function (value, name) {
      this.color_form = value;
      this.color_name = name;

    },

  
  },
};
</script>


